import { useTranslation } from '@mntn-dev/i18n'
import {
  Badge,
  Heading,
  IconButton,
  LinkButton,
  Stack,
  Surface,
  type TestIds,
} from '@mntn-dev/ui-components'
import { themeDivideColorMap } from '@mntn-dev/ui-theme'
import type { PropsWithChildren } from 'react'
import { NotificationSurfaceSection } from './notification-surface-section.tsx'

type NotificationSurfaceProps = PropsWithChildren<
  {
    onClose: () => void
    onMarkAllAsRead: () => void
    unreadNotificationCount: number | undefined
  } & TestIds
>

const NotificationSurfaceComponent = ({
  onClose,
  onMarkAllAsRead,
  unreadNotificationCount = 0,
  children,
}: NotificationSurfaceProps) => {
  const { t } = useTranslation('notification')

  return (
    <Surface
      border
      padding="0"
      width="128"
      height="screen-minus-32"
      backgroundColor="secondary"
      gap="0"
      divide={false}
      className="left-16"
      dataTestId="notifications-modal"
      dataTrackingId="notifications-modal"
    >
      <Surface.Section backgroundColor="page-secondary">
        <Stack gap="2" padding="6" justifyContent="between">
          <Stack gap="2">
            <Heading fontSize="xl">{t('title')}</Heading>
            {unreadNotificationCount > 0 && (
              <Badge
                dataTestId="notifications-modal-unread-count-badge"
                dataTrackingId="notifications-modal-unread-count-badge"
                size="lg"
                value={unreadNotificationCount}
              />
            )}
          </Stack>
          <Stack columnGap="10">
            {unreadNotificationCount > 0 && (
              <LinkButton
                onClick={onMarkAllAsRead}
                dataTestId="notifications-modal-mark-all-read-button"
                dataTrackingId="notifications-modal-mark-all-read-button"
                className="self-center"
              >
                {t('actions.mark-all-read')}
              </LinkButton>
            )}
            <IconButton
              name="XMarkIcon"
              size="md"
              color="info"
              onClick={onClose}
              centered
            />
          </Stack>
        </Stack>
      </Surface.Section>
      <Surface.Section
        height="full"
        backgroundColor="page"
        className={`divide-y ${themeDivideColorMap.muted} overflow-y-auto`}
      >
        {children}
      </Surface.Section>
    </Surface>
  )
}

const NotificationSurfaceNamespace = Object.assign(
  NotificationSurfaceComponent,
  {
    Section: NotificationSurfaceSection,
  }
)

export {
  NotificationSurfaceNamespace as NotificationSurface,
  type NotificationSurfaceProps,
}
